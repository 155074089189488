import React from "react";
import Jumbotron from "../component/Jumbotron";
import Container from "@material-ui/core/Container";

function Home() {
  return (
    <div>
      <Container maxWidth="md">
        <Jumbotron class></Jumbotron>
      </Container>
    </div>
  );
}

export default Home;
